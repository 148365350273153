<div class="row">
  <div class="col-sm-6 center">
<div class="card">
  <h4 class="card-header">Submission Complete!</h4>
  <div class="card-body">
    <h5>Thank you for submitting</h5>
    <pre>{{data | json}}</pre>
  </div>
</div>
</div>
</div>
