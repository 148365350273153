import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../shared/services/auth.service';

import { GatherClient } from '@entersekt/web-gather-library';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  username: string | null;
  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl!: string;
  feedback!: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {
    this.username = localStorage.getItem('username');

    this.form = this.formBuilder.group({
      username: ['', Validators.required]
    });
    if (this.username) {
      this.form.get('username')?.setValue(this.username);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() {
    return this.form?.controls;
  }

  async notMe() {
    this.username = '';
    localStorage.removeItem('username');
    this.form.get('username')?.setValue('');
  }

  async onSubmit() {
    this.submitted = true;
    if (this.form?.invalid) {
      return;
    }

    if (!this.username) {
      localStorage.setItem('username', this.f.username.value);
    }

    this.loading = true;
    const client = new GatherClient(
      'https://browser-capabilities.experiments.strategics.co.za/gather'
    );
    const data = await client.gather(this.f.username.value);
    this.auth.login(this.f.username.value, data);
  }
}
