<div class="row">
  <div class="col-sm-6 center">
<div class="card ">
  <h4 class="card-header">Web Capabilities Experiment</h4>
  <div class="card-body">
    <div *ngIf="username">
      <h5 class="d-inline-block">Welcome back {{username}}</h5>
      <button class="btn btn-danger float-right" (click)="notMe()"> Not Me</button>
      <br><br>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          formControlName="username"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
        />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
        </div>
      </div>
      <div class="form-group">
        <div *ngIf="submitted && feedback" class="feedback-text">
          <div>{{ feedback }}</div>
        </div>
        <button [disabled]="loading" class="btn btn-primary">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
</div>
</div>
