import { Component, OnInit } from '@angular/core';
import { GatherData } from '@entersekt/web-gather-library/lib/model';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  data: GatherData;
  constructor(private auth: AuthService) {
    this.data = auth.data;
  }
}
