import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GatherData } from '@entersekt/web-gather-library/lib/model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn = false;
  data: GatherData;
  constructor(public router: Router) {}

  isLoggedIn(): boolean {
    if (!this.loggedIn) {
      this.router.navigate(['/login']);
      return false;
    } else return true;
  }

  async login(_username: string, data: GatherData): Promise<void> {
    this.loggedIn = true;
    this.router.navigate(['/']);
    this.data = data;
    return Promise.resolve();
  }
}
